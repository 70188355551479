<template>
    <div style="border-radius: 8px" class="row journal-line">
        <div class="col-12 d-flex product-details-border position-relative pe-0">
            <div class="row w-100 pe-lg-0 me-1 py-2">
                <div v-if="isItem" class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="product_id">Product</label>
                        <vField
                            v-model="data.product_id"
                            name="product_id"
                            type="text"
                            class="form-control d-none"
                        />
                        <v-select
                            placeholder="Select Product"
                            v-model="data.product_id"
                            :options="products"
                            label="text"
                            :reduce="name => name.id"
                            disabled
                        />
                    </div>
                </div>
                <div v-else class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="product_id">Account Heads</label>
                        <vField
                            v-model="data.account_head_id"
                            name="product_id"
                            type="text"
                            class="form-control d-none"
                        />
                        <v-select
                            placeholder="Select Head"
                            v-model="data.account_head_id"
                            :options="accountHeads"
                            label="name"
                            :reduce="name => name.id"
                            disabled
                        />
                    </div>
                </div>

                <template v-if="isPO">
                  <div v-if="isItem" class="col-md-2 col-12">
                    <div class="mb-1">
                      <label class="form-label" for="rate">Confirm Rate</label>
                      <vField
                          v-model="data.rate_confirm"
                          name="rate"
                          type="number"
                          class="form-control text-right"
                          readonly
                      />
                    </div>
                  </div>
                  <div v-if="isItem" class="col-md-2 col-12">
                    <div class="mb-1">
                      <label class="form-label" for="quantity">Confirm Quantity</label>
                      <vField
                          v-model="data.quantity_confirm"
                          name="quantity"
                          type="number"
                          class="form-control text-right"
                          readonly
                      />
                    </div>
                  </div>
                  <div v-if="!isItem" class="col-md-2 col-12">
                    <div class="mb-1">
                      <label class="form-label" for="rate">Confirm Amount</label>
                      <vField
                          v-model="data.rate_confirm"
                          name="rate"
                          type="number"
                          class="form-control text-right"
                          readonly
                      />
                    </div>
                  </div>
                </template>
                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="currency">Currency</label>
                        <vField
                                v-model="data.currency"
                                name="currency"
                                type="text"
                                class="form-control d-none"
                        />
                        <v-select
                                placeholder="Select Currency"
                                v-model="data.currency"
                                :options="currencies"
                                :reduce="name => name"
                                :disabled="isDisabled"
                        />
                    </div>
                </div>
                <template v-if="isItem">
                    <div class="col-md-2 col-12">
                      <div class="mb-1">
                        <label class="form-label" for="rate">Rate</label>
                        <vField
                          v-model="data.rate"
                          name="rate"
                          type="number"
                          class="form-control text-right"
                          :disabled="isDisabled"
                        />
                      </div>
                    </div>
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <label class="form-label" for="quantity">Requisition Quantity</label>
                            <vField
                                v-model="data.requisition_qty"
                                name="quantity"
                                type="number"
                                class="form-control text-right"
                                @change="onChangeAmountQuantity(data, $event.target.value)"
                                disabled="disabled"
                            />
                        </div>
                    </div>
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <label class="form-label" for="quantity">Ordered Quantity</label>
                            <vField
                                v-model="data.purchase_order_qty"
                                name="quantity"
                                type="number"
                                class="form-control text-right"
                                @change="onChangeAmountQuantity(data, $event.target.value)"
                                disabled="disabled"
                            />
                        </div>
                    </div>
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <label class="form-label" for="quantity">Remaining Quantity</label>
                            <vField
                                v-model="remainingQty"
                                name="quantity"
                                type="number"
                                class="form-control text-right"
                                @change="onChangeAmountQuantity(data, $event.target.value)"
                                disabled="disabled"
                            />
                        </div>
                    </div>
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <label class="form-label" for="quantity">Quantity</label>
                            <vField
                                v-model="data.quantity"
                                name="quantity"
                                type="number"
                                class="form-control text-right"
                                :class="{'is-invalid' : data.quantity < 1}"
                                @change="onChangeAmountQuantity(data, $event.target.value)"
                                @blur="validateQuantity"
                                :disabled="remainingQty < 1 || isDisabled"
                            />
                            <p v-if="data.quantity < 1" class="text-danger mt-1">Quantity cannot be 0</p>
                        </div>
                    </div>
                </template>

                <div v-if="isItem" class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Amount</label>
                        <vField
                            readonly
                            v-model="amount"
                            name="amount"
                            type="number"
                            class="form-control text-right"
                            :disabled="isDisabled"
                        />
                    </div>
                </div>

                <div v-else class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Amount</label>
                        <vField
                            v-model="data.amount"
                            name="amount"
                            type="number"
                            class="form-control text-right"
                            @change="onChangeAmountQuantity(data, $event.target.value)"
                            :disabled="isDisabled"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="vat">VAT Rate</label>
                        <vField
                            v-model="data.vat"
                            id="vat"
                            as="select"
                            name="vat"
                            class="form-select text-right"
                            :disabled="isDisabled"
                        >
                            <option
                                v-for="(vat, i) in vatRate"
                                :value="vat.value"
                                :key="i"
                            >
                                {{vat.label}}
                            </option>
                        </vField>
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="vat_amount">VAT amount</label>
                        <vField
                            v-model="data.vat_amount"
                            name="vat_amount"
                            type="number"
                            class="form-control text-right"
                            :disabled="isDisabled"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="total_amount">Total Amount</label>
                        <vField
                            v-model="totalAmount"
                            readonly
                            name="total_amount"
                            type="number"
                            class="form-control text-right"
                            :disabled="isDisabled"
                        />
                    </div>
                </div>

                <div class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="description">Description</label>
                         <vField
                             v-model="data.description"
                            style="height: 38px"
                            as="textarea"
                            name="description"
                            type="number"
                            class="form-control"
                            :disabled="isDisabled"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref,computed } from "@vue/runtime-core"
import {inject, watch} from "vue";
const emit = defineEmits(['callConfirmLimit'])
let accountEditable = ref(0)

const pr = defineProps({
  title: String,
  data: Object,
  isItem: Boolean,
  isPOCreate: Boolean,
  isPO: {
    type: Boolean,
    default: false
  },
  isDisabled: {
    type: Boolean,
    default: false
  },
  index: Number,
  vatRate: Array,
  products: Array,
  currencies: Array,
  accountHeads: Array
})

const showError = inject('showError')

const amount = computed(() => {
    if(pr.isItem) {
        return pr.data.rate * pr.data.quantity
    }
    if(!pr.isItem) {
        return pr.data.amount
    }
})

const vatAmount = computed(() => {
    if(pr.data.vat && pr.isItem) {
        return (pr.data.vat/100 * amount.value).toFixed(2)
    }

    if(pr.data.vat) {
        return pr.data.vat/100 * pr.data.amount
    }
})
const remainingQty = computed(() => {
    if(pr.isPO) {
      return pr.data.quantity_confirm - pr.data.purchase_order_qty
    }
    return pr.data.requisition_qty - pr.data.purchase_order_qty
})

watch(remainingQty, (newValue) => {
  pr.data.quantity = newValue;
})

watch(vatAmount, (newValue, oldValue) => {
  if(! isNaN(oldValue)) {
    pr.data.vat_amount  = newValue;
  }
})

const totalAmount = computed(() => {
    if(pr.data.vat_amount == 0) {
        return parseInt(amount.value)
    }
    if(pr.isItem) {
        return parseInt(amount.value) + parseInt(pr.data.vat_amount)
    }
    return parseInt(pr.data.amount) + parseInt(pr.data.vat_amount)
})

const validateQuantity = () => {
  if(pr.data.quantity > remainingQty.value) {
    if(!pr.isPO) {
      showError("Quantity cannot be more than remaining quantity")
    }
    pr.data.quantity = remainingQty.value
  }
}

function onChangeAmountQuantity(data, inputValue){
  emit('callConfirmLimit', data, inputValue)
}

</script>


<style scoped>
.journal-line{
        border: 1px solid #f0f2f5;
    }
    .journal-line:hover{
        background: #f0f2f5;
    }
</style>

